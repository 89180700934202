.section1_kv {
  padding-bottom: 30.21%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-dataanalysis-5th/section1_kv_before_load_pc.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
  }
}

@media (max-width: 1023px) {
  .section1_kv {
    padding-bottom: 83.33%;

    &::before {
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-dataanalysis-5th/section1_kv_before_load_m.png');
    }
  }
}

.section2_list_number {
  counter-reset: list-number;
  li {
    counter-increment: list-number;
    &::before {
      content: counter(list-number) '.';
      margin-right: 4px;
    }
  }
}

.section4_slider {
  :global {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 52px;
        width: 42px;
        height: 42px;
        background-color: #f2f1f0;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-dataanalysis-5th/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 26px 26px;
        border-radius: 4px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(10.6% + 12px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(10.6% + 12px);
        transform: none;
      }
    }

    .slick-dots {
      bottom: 0;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 0.5;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #737373;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #737373;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .section4_slider {
    :global {
      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          bottom: 48px;
          width: 24px;
          height: 24px;
          background-size: 16px 16px;
        }
        &.slick-prev {
          left: 0;
        }
        &.slick-next {
          right: 0;
        }
      }
    }
  }
}

.curriculum_slider {
  :global {
    .slick-slide {
      width: 164px;
    }

    .slick-dots {
      position: relative;
      padding-top: 18px;
      bottom: 0;
      z-index: 1;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 6px;
          height: 6px;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 1;
            content: '';
            width: 6px;
            height: 6px;
            background-color: #fff;
            border-radius: 50%;
            opacity: 0.5;
          }
        }
        &.slick-active {
          button {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// 공통 dot 요소
.text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  &_section6 {
    padding-left: 15px;

    &::before {
      top: 9px;
      left: 0;
      content: '';
      background-color: #262626;
    }
  }

  &_section7 {
    padding-left: 11px;

    &::before {
      top: 9px;
      left: 0;
      content: '';
      background-color: #d4d4d4;
    }
  }

  &_section13 {
    padding-left: 15px;

    &::before {
      top: 16px;
      left: 0;
      content: '';
      background-color: #262626;
    }
  }

  &_section_teacher {
    padding-left: 11px;

    &::before {
      top: 9px;
      left: 0;
      content: '';
      background-color: #737373;
    }
  }
}

@media (max-width: 1023px) {
  .text_dot {
    padding-left: 11px;

    &::before {
      top: 9px;
      left: 0;
      width: 2px;
      height: 2px;
    }

    &_section6 {
      &::before {
        top: 9px;
      }
    }

    &_section7 {
      &::before {
        top: 9px;
      }
    }

    &_section13 {
      &::before {
        top: 16px;
      }
    }

    &_section_teacher {
      padding-left: 10px;

      &::before {
        top: 8px;
      }
    }
  }
}

.section8_slider {
  :global {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #404040;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-aos-4th/section8_slider_arrow_pc.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }

    .slick-dots {
      bottom: 0;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 0.5;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #fff;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .section8_slider {
    :global {
      .slick-slide {
        width: 258px;
      }

      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          width: 32px;
          height: 32px;
          background-color: transparent;
          background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-aos-4th/section14_slider_arrow_m.png');
          background-size: 24px 24px;
          border-radius: 50%;
        }
        &.slick-prev {
          left: calc(50% - 36px);
          transform: rotate(180deg);
        }
        &.slick-next {
          right: calc(50% - 36px);
          transform: none;
        }
      }
    }
  }
}

.section11_slider {
  :global {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #404040;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-aos-4th/section8_slider_arrow_pc.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }

    .slick-dots {
      bottom: 0;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 0.5;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #fff;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .section11_slider {
    :global {
      .slick-slide {
        width: 258px;
      }
    }
  }
}

@media (min-width: 1100px) and (max-width: 1280px) {
  .section5_mediaquery {
    > div {
      &:nth-of-type(2) {
        > div {
          margin-left: 16vw;
        }
      }
      &:nth-of-type(3) {
        > div {
          margin-left: 34vw;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1099px) {
  .section5_mediaquery {
    > div {
      &:nth-of-type(2) {
        > div {
          margin-left: 12vw;
        }
      }
      &:nth-of-type(3) {
        > div {
          margin-left: 30vw;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .section10_mediaquery {
    h6 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
  .section16_mediaquery {
    h6 {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
  }
}

.curriculum_accordion_wrap {
  li {
    &:first-of-type {
      .curriculum_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .curriculum_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .curriculum_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: #a3a3a3;
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .curriculum_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 50px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 95px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }

  &_shortver {
    .curriculum_accordion_dot_ul {
      &::before {
        top: 40px;
        height: calc(100% - 80px);
      }
    }
  }
}

.review_slider {
  :global {
    .slick-slide {
      width: 340px;
    }

    .slick-dots {
      position: relative;
      padding-top: 35px;
      bottom: 0;
      z-index: 1;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 6px;
          height: 6px;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 1;
            content: '';
            width: 6px;
            height: 6px;
            background-color: #737373;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .review_slider {
    :global {
      .slick-slide {
        width: 320px;
      }
    }
  }
}

.benefit_slider {
  :global {
    .slick-slide {
      width: 340px;
    }

    .slick-dots {
      position: relative;
      padding-top: 35px;
      bottom: 0;
      z-index: 1;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 6px;
          height: 6px;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 1;
            content: '';
            width: 6px;
            height: 6px;
            background-color: #737373;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .benefit_slider {
    :global {
      .slick-slide {
        width: 296px;
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
