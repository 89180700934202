.section1_kv {
  padding-bottom: 28.13%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-14th/section1_kv_before_pc.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
  }
}

@media (max-width: 1023px) {
  .section1_kv {
    padding-bottom: 94.44%;

    &::before {
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-14th/section1_kv_before_m.png');
    }
  }
}

.section2_intro_list {
  counter-reset: list-number;
  p {
    position: relative;
    padding-left: 18px;
    counter-increment: list-number;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: counter(list-number) '.';
    }
  }
}

.section3_slider {
  :global {
    .slick-slide {
      width: 258px;
    }

    .slick-dots {
      bottom: 0;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 0.5;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #737373;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #262626;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// 공통 dot 요소
.text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  &_section7 {
    padding-left: 15px;

    &::before {
      top: 9px;
    }
  }

  &_section8 {
    padding-left: 15px;

    &::before {
      top: 9px;
      background-color: #737373;
    }
  }

  &_section14 {
    padding-left: 15px;

    &::before {
      top: 10px;
      background-color: #737373;
    }
  }

  &_section15 {
    padding-left: 15px;

    &::before {
      top: 9px;
      background-color: #fff;
    }
  }

  &_section18 {
    padding-left: 15px;

    &::before {
      top: 10px;
      background-color: #262626;
    }
  }
}

@media (max-width: 1023px) {
  .text_dot {
    padding-left: 11px;

    &::before {
      top: 9px;
      left: 0;
      width: 2px;
      height: 2px;
    }

    &_section7 {
      padding-left: 11px;

      &::before {
        top: 9px;
      }
    }

    &_section8 {
      padding-left: 11px;

      &::before {
        top: 9px;
      }
    }

    &_section14 {
      padding-left: 11px;

      &::before {
        top: 9px;
      }
    }

    &_section15 {
      padding-left: 11px;

      &::before {
        top: 9px;
      }
    }

    &_section18 {
      padding-left: 11px;

      &::before {
        top: 8px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .section6_mediaquery {
    li {
      p {
        font-size: 14px;
      }
    }
  }
}

.section9_slider {
  :global {
    .slick-slide {
      transition: all 0.3s;
      transform: scale(1.2);

      &:not(.slick-center) {
        transform: scale(0.7);
      }
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial !important;
        bottom: -124px;
        width: 40px;
        height: 40px;
        background-color: #f5f5f5;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-14th/section9_slider_arrow_pc.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1501px) {
  .section9_slider {
    :global {
      .slick-list {
        height: 35vw;
      }

      .slick-slide {
        transition: all 0.3s;
        transform: none;

        &:not(.slick-center) {
          transform: none;
        }
      }

      .slick-arrow {
        &.slick-prev,
        &.slick-next {
          top: initial !important;
          bottom: -124px;
          width: 40px;
          height: 40px;
          background-color: #f5f5f5;
          background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-14th/section9_slider_arrow_pc.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24px 24px;
          z-index: 2;

          &::before {
            display: none;
          }

          &.slick-disabled {
            opacity: 0.5;
          }
        }
        &.slick-prev {
          left: calc(50% - 12px - 40px);
          transform: rotate(180deg);
        }
        &.slick-next {
          right: calc(50% - 12px - 40px);
          transform: none;
        }
      }

      .slick-dots {
        bottom: -80px;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              content: '';
              width: 100%;
              height: 100%;
              background-color: #737373;
              opacity: 0.5;
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                background-color: #262626;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .section9_mediaquery {
      height: initial;
      padding: 0 10px;
    }
  }
}

@media (max-width: 1023px) {
  .section9_slider {
    :global {
      .slick-slide {
        transform: none;

        &:not(.slick-center) {
          transform: none;
        }
      }

      .slick-dots {
        bottom: 0;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              content: '';
              width: 100%;
              height: 100%;
              background-color: #737373;
              opacity: 0.5;
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                background-color: #262626;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.section10_div_deco {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
    width: 58.696px;
    height: 108px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &::before {
    left: 0;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-14th/section10_deco_left.png');
  }
  &::after {
    right: 0;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-frontend-14th/section10_deco_right.png');
  }
}

@media (max-width: 1023px) {
  .section10_div_deco {
    &::before,
    &::after {
      top: 50%;
      transform: translateY(-50%);
      width: 48.338px;
      height: 88.941px;
    }
  }
}

.company_logo_flow_slider {
  $firstAnimationSpeed: 15s; // 첫 슬라이더 기준 속도
  $secondAnimationSpeed: calc(15s * 1.143); // 두 번째 슬라이더는 첫 번째 슬라이더보다 1.143배 빠름

  // 첫 번째 슬라이더 애니메이션 (왼쪽으로 흐름)
  @keyframes scrollFirst {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(
        calc(-190px * 12 - 12px * 12 + 186.3px)
      ); /* 12개의 슬라이드, 갭 12px, 변종 수치보정 추가 */
    }
  }

  position: relative;
  width: 100%;
  height: 78px;
  overflow: hidden;

  &_track {
    display: flex;
    gap: 12px;
    width: calc(
      190px * 36 + 12px * 36 - 186.3px
    ); /* 12개의 슬라이드 * 3 반복, 변종 수치보정 추가 */
    animation: scrollFirst $firstAnimationSpeed linear infinite;
  }

  .slider_item {
    flex-shrink: 0;
    height: 78px;

    &:nth-of-type(1),
    &:nth-of-type(13),
    &:nth-of-type(25) {
      width: 190px;
    }

    &:nth-of-type(2),
    &:nth-of-type(14),
    &:nth-of-type(26) {
      width: 190px;
    }

    &:nth-of-type(3),
    &:nth-of-type(15),
    &:nth-of-type(27) {
      width: 223.4px;
    }

    &:nth-of-type(4),
    &:nth-of-type(16),
    &:nth-of-type(28) {
      width: 194.5px;
    }

    &:nth-of-type(5),
    &:nth-of-type(17),
    &:nth-of-type(29) {
      width: 190px;
    }

    &:nth-of-type(6),
    &:nth-of-type(18),
    &:nth-of-type(30) {
      width: 190px;
    }

    &:nth-of-type(7),
    &:nth-of-type(19),
    &:nth-of-type(31) {
      width: 140px;
    }

    &:nth-of-type(8),
    &:nth-of-type(20),
    &:nth-of-type(32) {
      width: 140px;
    }

    &:nth-of-type(9),
    &:nth-of-type(21),
    &:nth-of-type(33) {
      width: 190px;
    }

    &:nth-of-type(10),
    &:nth-of-type(22),
    &:nth-of-type(34) {
      width: 190px;
    }

    &:nth-of-type(11),
    &:nth-of-type(23),
    &:nth-of-type(35) {
      width: 190px;
    }

    &:nth-of-type(12),
    &:nth-of-type(24),
    &:nth-of-type(36) {
      width: 190px;
    }
  }

  &_second {
    .company_logo_flow_slider_track {
      animation: scrollSecond $secondAnimationSpeed linear infinite reverse; /* 애니메이션을 reverse로 설정해 오른쪽으로 이동 */
    }
  }
}

@media (max-width: 1023px) {
  .company_logo_flow_slider {
    @keyframes scrollFirst {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-93px * 6 - 6px * 6 - 18px)); /* 6개의 슬라이드 */
      }
    }

    width: 100%;
    height: 38px;

    &_track {
      gap: 6px;
      width: calc(93px * 18 + 6px * 18 + 18px); /* 6개의 슬라이드 * 3 반복 */
    }

    .slider_item {
      height: 38px;

      &:nth-of-type(1),
      &:nth-of-type(7),
      &:nth-of-type(13) {
        width: 93px;
      }

      &:nth-of-type(2),
      &:nth-of-type(8),
      &:nth-of-type(14) {
        width: 93px;
      }

      &:nth-of-type(3),
      &:nth-of-type(9),
      &:nth-of-type(15) {
        width: 93px;
      }

      &:nth-of-type(4),
      &:nth-of-type(10),
      &:nth-of-type(16) {
        width: 109px;
      }

      &:nth-of-type(5),
      &:nth-of-type(11),
      &:nth-of-type(17) {
        width: 95px;
      }

      &:nth-of-type(6),
      &:nth-of-type(12),
      &:nth-of-type(18) {
        width: 93px;
      }
    }

    &_second {
      margin-top: 8px;

      @keyframes scrollSecond {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-93px * 6 - 6px * 6 + 58px));
        }
      }

      .company_logo_flow_slider_track {
        display: flex;
        gap: 8px;
        width: calc(93px * 18 + 6px * 18 - 58px);
      }

      .slider_item {
        height: 38px;

        &:nth-of-type(1),
        &:nth-of-type(7),
        &:nth-of-type(13) {
          width: 93px;
        }

        &:nth-of-type(2),
        &:nth-of-type(8),
        &:nth-of-type(14) {
          width: 64px;
        }

        &:nth-of-type(3),
        &:nth-of-type(9),
        &:nth-of-type(15) {
          width: 64px;
        }

        &:nth-of-type(4),
        &:nth-of-type(10),
        &:nth-of-type(16) {
          width: 93px;
        }

        &:nth-of-type(5),
        &:nth-of-type(11),
        &:nth-of-type(17) {
          width: 93px;
        }

        &:nth-of-type(6),
        &:nth-of-type(12),
        &:nth-of-type(18) {
          width: 93px;
        }
      }
    }
  }
}

.section11_slider {
  :global {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #404040;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-aos-4th/section8_slider_arrow_pc.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }

    .slick-dots {
      bottom: 0;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 0.5;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              background-color: #fff;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .section11_slider {
    :global {
      .slick-slide {
        width: 258px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .section16_mediaquery {
    h6 {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
  }
}

.section18_custom_dl {
  &:nth-of-type(2) {
    dt {
      margin-top: 10px;
    }
  }
}

.curriculum_accordion_wrap {
  li {
    &:first-of-type {
      .curriculum_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .curriculum_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .curriculum_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: #a3a3a3;
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .curriculum_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 40px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 90px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }

  &_section4 {
    .curriculum_accordion_dot_ul {
      &::before {
        top: 56px;
        height: calc(100% - 85px);
      }
    }
  }

  &_section10 {
    .curriculum_accordion_dot_ul {
      &::before {
        top: 68px;
        height: calc(100% - 125px);
      }
    }
  }

  &_section14 {
    .curriculum_accordion_dot_ul {
      &::before {
        top: 30px;
        height: calc(100% - 60px);
      }
    }
  }
}

@media (max-width: 1023px) {
  .curriculum_accordion_wrap {
    &_section10 {
      .section10_text_deco {
        li {
          padding: 12px 0;
          border-bottom: 1px solid #e5e5e5;

          &:first-of-type {
            padding: 0 0 12px 0;
          }
          &:last-of-type {
            padding: 12px 0 0 0;
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
