.section1_kv {
  padding-bottom: 28.13%;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;

    &::-webkit-media-controls {
      display: none !important;
    }
  }
}

@media (max-width: 1023px) {
  .section1_kv {
    padding-bottom: 117.78%;
  }
}

.section2_intro_list {
  counter-reset: list-number;
  li {
    position: relative;
    padding-left: 18px;
    counter-increment: list-number;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: counter(list-number) '.';
    }
  }
}

// 공통 dot 요소
.text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  &_curriculum1 {
    &::before {
      background-color: #fff;
    }
  }

  &_curriculum2 {
    &::before {
    }
  }

  &_section5 {
    &::before {
      background-color: #d4d4d4;
    }
  }
}
@media (max-width: 1023px) {
  .text_dot {
    padding-left: 11px;

    &::before {
      top: 9px;
      left: 0;
      width: 2px;
      height: 2px;
    }
  }
}

.section3_bg {
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 60px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section3_bg_pc.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }
}

@media (max-width: 1023px) {
  .section3_bg {
    &::before {
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section3_bg_m.png');
    }
  }
}

.section4_bg {
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 232px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section6_bg_pc.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }
}

@media (max-width: 1023px) {
  .section4_bg {
    &::before {
      height: 120px;
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section6_bg_m.png');
    }
  }
}

.curriculum_accordion_wrap {
  li {
    &:first-of-type {
      .curriculum_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .curriculum_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .curriculum_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: #a3a3a3;
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .curriculum_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 40px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 95px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }
}

@media (max-width: 1023px) {
  .curriculum_accordion_wrap {
    .curriculum_accordion_ul {
      &:not(:first-of-type) {
        li:first-of-type {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
}

.section6_slider {
  .section6_slider_item {
    > div {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
      }
    }
  }

  :global {
    .slick-slide {
      transition: all 0.3s;

      &.slick-center {
        > div > div > div > div {
          &::before {
            display: none;
          }
        }
      }
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial !important;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #171717;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-16th/section6_slider_arrow_pc.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }
  }
}

@media (max-width: 1023px) {
  .section6_slider {
    :global {
      .slick-dots {
        bottom: 0;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              content: '';
              width: 100%;
              height: 100%;
              background-color: #fff;
              opacity: 0.5;
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.section10_bg {
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 107.31px;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section10_bg_pc.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }
}

@media (max-width: 1023px) {
  .section10_bg {
    &::before {
      height: 60px;
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-backendp-14th/section10_bg_m.png');
    }
  }
}

.company_logo_flow_slider {
  $firstAnimationSpeed: 15s; // 첫 슬라이더 기준 속도
  $secondAnimationSpeed: calc(15s * 1.143); // 두 번째 슬라이더는 첫 번째 슬라이더보다 1.143배 빠름

  // 첫 번째 슬라이더 애니메이션 (왼쪽으로 흐름)
  @keyframes scrollFirst {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-200px * 7 - 8px * 7)); /* 7개의 슬라이드 */
    }
  }

  position: relative;
  width: 100%;
  height: 80px;
  overflow: hidden;

  &_track {
    display: flex;
    gap: 8px;
    width: calc(200px * 21 + 8px * 21); /* 7개의 슬라이드 * 3 반복 */
    animation: scrollFirst $firstAnimationSpeed linear infinite;
  }

  &_item {
    width: 200px;
    height: 80px;
  }

  &_second {
    margin-top: 7px;

    // 두 번째 슬라이더 애니메이션 (오른쪽으로 흐름)
    @keyframes scrollSecond {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-200px * 8 - 8px * 8));
      }
    }

    .company_logo_flow_slider_track {
      display: flex;
      gap: 8px;
      width: calc(200px * 24 + 8px * 24); /* 8개의 슬라이드 * 3 반복 */
      animation: scrollSecond $secondAnimationSpeed linear infinite reverse; /* 애니메이션을 reverse로 설정해 오른쪽으로 이동 */
    }
  }
}

@media (max-width: 1023px) {
  .company_logo_flow_slider {
    @keyframes scrollFirst {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-150px * 7 - 8px * 7)); /* 7개의 슬라이드 */
      }
    }

    width: 100%;
    height: 60px;

    &_track {
      width: calc(150px * 21 + 8px * 21); /* 7개의 슬라이드 * 3 반복 */
    }

    &_item {
      width: 150px;
      height: 60px;
    }

    &_second {
      margin-top: 7px;

      @keyframes scrollSecond {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-150px * 8 - 8px * 8));
        }
      }

      .company_logo_flow_slider_track {
        width: calc(150px * 24 + 8px * 24);
      }
    }
  }
}

.benefit_slider {
  :global {
    .slick-slide {
      width: 419px;
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #262626;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }
  }
}

@media (max-width: 1023px) {
  .benefit_slider {
    :global {
      .slick-slide {
        width: 312px;
      }

      .slick-dots {
        position: initial;
        margin-top: 30px;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              opacity: 1;
              content: '';
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
