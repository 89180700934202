.section1_kv {
  padding-bottom: 31.77%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-2nd/section1_kv_before_pc.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
  }
}
@media (max-width: 1023px) {
  .section1_kv {
    padding-bottom: 126.39%;

    &::before {
      background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-growth-2nd/section1_kv_before_m.png');
    }
  }
}

// 공통 dot 요소
.text_dot {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
    width: 3px;
    height: 3px;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  &_section3 {
    &::before {
      background-color: #fff;
    }
  }

  &_section8 {
    &::before {
      background-color: #767676;
    }
  }

  &_section10 {
    &::before {
      background-color: #fff;
    }
  }

  &_section12 {
    &::before {
      background-color: #262626;
    }

    &_light {
      &::before {
        background-color: #737373;
      }
    }
  }

  &_section13 {
    &::before {
      background-color: #ff7710;
    }
  }

  &_section16 {
    &::before {
      background-color: #262626;
    }
  }
}

@media (max-width: 1023px) {
  .text_dot {
    padding-left: 11px;

    &::before {
      top: 8px;
      left: 0;
      width: 2px;
      height: 2px;
    }

    &_section8 {
      &::before {
        background-color: #262626;
      }
    }

    &_section10 {
      &::before {
        background-color: #d4d4d4;
      }
    }

    &_section13 {
      padding-left: 0;
      &::before {
        display: none;
      }
    }

    &_section16 {
      &::before {
        background-color: #262626;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .section5_mediaquery {
    li {
      p {
        font-size: 14px;
      }
    }
  }
}

.section7_slider {
  :global {
    .slick-dots {
      position: initial;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 0.5;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.section10_slider {
  :global {
    .slick-slide {
      width: 258px;
    }

    .slick-dots {
      position: initial;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 0.5;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.section14_slider {
  :global {
    .slick-slide {
      width: 418px;
    }

    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: initial;
        bottom: 0;
        width: 40px;
        height: 40px;
        background-color: #171717;
        background-image: url('https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-ugm-3rd/section4_slider_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px 24px;
        border-radius: 4px;
        z-index: 2;

        &:hover {
          background-color: #000;
        }

        &::before {
          display: none;
        }

        &.slick-disabled {
          opacity: 0.5;
        }
      }
      &.slick-prev {
        left: calc(50% - 12px - 40px);
        transform: rotate(180deg);
      }
      &.slick-next {
        right: calc(50% - 12px - 40px);
        transform: none;
      }
    }
  }
}

@media (max-width: 1023px) {
  .section14_slider {
    :global {
      .slick-slide {
        width: 312px;
      }

      .slick-dots {
        position: initial;

        li {
          width: 6px;
          height: 6px;
          margin: 0 4px;

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              top: 0;
              left: 0;
              opacity: 0.5;
              content: '';
              width: 100%;
              height: 100%;
              background-color: #fff;
              border-radius: 50%;
            }
          }
          &.slick-active {
            button {
              &::before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.section15_slider {
  :global {
    .slick-slide {
      width: 258px;
    }

    .slick-dots {
      position: initial;

      li {
        width: 6px;
        height: 6px;
        margin: 0 4px;

        button {
          width: 100%;
          height: 100%;
          padding: 0;

          &::before {
            top: 0;
            left: 0;
            opacity: 0.5;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.curriculum_accordion_wrap {
  li {
    &:first-of-type {
      .curriculum_accordion_dot_box {
        align-items: flex-start;

        span {
          &::before {
            display: none;
          }
        }
      }
    }

    &:last-of-type {
      .curriculum_accordion_dot_box {
        span {
          &::after {
            display: none;
          }
        }
      }
    }

    .curriculum_accordion_dot_box {
      span {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          content: '';
          width: 2px;
          height: 999vh;
          background-color: #a3a3a3;
          z-index: 0;
        }

        &::before {
          top: calc(-999vh - 2px);
        }
        &::after {
          bottom: calc(-999vh - 2px);
        }
      }
    }
  }

  .curriculum_accordion_dot_ul {
    position: relative;

    &::before {
      position: absolute;
      top: 54px;
      left: 5.4px;
      content: '';
      width: 2px;
      height: calc(100% - 96px);
      background-color: #a3a3a3;
      z-index: 0;
    }
  }
}

.faqItem {
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}

.faq_item_title {
  position: relative;
  padding-left: 23px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q.';
    margin-right: 4px;
    color: #ff7710;
  }
}

@media (max-width: 1023px) {
  .faq_item_title {
    padding-left: 18px;
  }
}
